import Printer from "@/models/printer";
import BaseProvider from "./baseprovider"
import {PagedParams} from '@/models/paged/pagedparams';
import PagedStores from '@/models/paged/pagedstores';
import {globalStore} from '@/stores/globalstore';
import PagedPrinters from '@/models/paged/pagedprinters';
import Store from '@/models/store';

export default class PrinterProvider extends BaseProvider {
    public async fetchPrinters(): Promise<Printer[]> {
        let p: String = '';
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`printers${p}`);

        return response.data as Printer[];
    }

    public async fetchPagedPrinters(
        pagedParams: PagedParams
    ): Promise<PagedPrinters> {
        let p: String = '';
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`printers${p}`);

        return new PagedPrinters(response?.data);
    }

    public async getPrinterFromUid(printerUid: string): Promise<Printer> {
        const response = await (await this.getApiV1()).get(`/printers/${printerUid}`);
        return this.getPrinter(response.data);
    }

    public async savePrinter(printer: Printer): Promise<Printer> {
        const response = await (await this.getApiV1()).post(`/printers/`, printer.toJson());
        const savedStore = this.getPrinter(response.data);
        return savedStore;
    }

    private getPrinter(data: any): Printer {
        const s = new Printer();
        s.fromJson(data);
        return s;
    }
}