import POS from "@/models/pos";
import PagedPOS from "@/models/paged/pagedpos";
import BaseProvider from "./baseprovider"
import {PagedParams} from '@/models/paged/pagedparams';

export default class POSProvider extends BaseProvider {
    public async fetchPagedPOS(entityIds: number[], storeIds: number[], pagedParams?: PagedParams): Promise<PagedPOS> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("storeIds", storeIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'license_code',
            sortOrder: 'asc',
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`/pos${p}`);

        const pagedItems = new PagedPOS(response.data);

        return pagedItems;
    }

    public async getPOSFromUid(posUid: string): Promise<POS> {
        const response = await (await this.getApiV1()).get(`/pos/${posUid}`);

        const poss = this.getPOS(response.data);

        return poss;
    }

    public async getClickAndCollect(storeId: number) {
        const response = await (await this.getApiV1()).get(`/pos/clickandcollect/${storeId}`);
        return this.getPOS(response.data?.length > 0 ? response.data[0] : null);
    }

    public async savePOS(pos: POS): Promise<POS> {
        const response = await (await this.getApiV1()).post(`/pos/`, pos);

        const items = this.getPOS(response.data);

        return items;
    }

    private getPOS(data: any): POS {
        const pos = new POS(data);

        return pos;
    }
}
