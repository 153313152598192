import Item, { ItemPrice } from "@/models/item"
import PagedItems from "@/models/paged/pageditems";
import BaseProvider from "./baseprovider"
import ItemStore from '@/models/itemstore';
import { PagedParams } from '@/models/paged/pagedparams';
import Stock from "@/models/stock";
import IItemStat from '@/models/itemstat';
import IItemStock from '@/models/itemstock';
import PagedElements from '@/models/paged/pagedelements';
import PriceListProvider from "./pricelistprovider";
import PriceList from "@/models/pricelist";
import { ItemDocumentLineItem } from "@/models/repository/itemdocumentrepository";
import moment from 'moment';

export default class ItemProvider extends BaseProvider {
    public async fetchPagedItems(entityIds: number[], pagedParams?: PagedParams): Promise<PagedItems> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/items${p}`);

        const pagedItems = new PagedItems(response.data);

        return pagedItems;
    }

    public async getSaleHistoryFromUid(itemUid: string, entityIds: number[], pagedParams?: PagedParams,
        params?: {
            storeIds?: number[];
            posIds?: number[];
            journalIds?: number[],
            journalTypeIds?: number[],
            flowTypeIds?: number[],
            backoffice?: boolean,
            fromDate?: Date,
            toDate?: Date
        }): Promise<PagedElements<any>> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'date',
            sortOrder: 'desc'
        });
        p = p.addParams("storeIds", params?.storeIds, true);
        p = p.addParams("posIds", params?.posIds, true);
        p = p.addParams("journalIds", params?.journalIds, true);
        p = p.addParams("journalTypeIds", params?.journalTypeIds, true);
        p = p.addParams("flowTypeIds", params?.flowTypeIds, true);
        p = p.addParams("backoffice", [params?.backoffice], false);

        p = p !== '' ? `?${p}` : '';

        if (params?.fromDate && params?.toDate)
            p = `/${moment(params.fromDate).format('yyyy-MM-DD HH:mm:ss')}/${moment(params.toDate).format('yyyy-MM-DD HH:mm:ss')}${p}`;

        console.log(`/items/${itemUid}/history/sale${p}`);

        const response = await (await this.getApiV2()).get(`/items/${itemUid}/history/sale${p}`);

        return new PagedElements(response.data);
    }

    public async getPurchaseHistoryFromUid(itemUid: string, entityIds: number[], top: number): Promise<any> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("top", [top]);

        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/items/${itemUid}/history/purchase${p}`);

        return response.data;
    }

    public async getCustomerSaleHistoryFromUid(itemUid: string, customerUid: string, entityIds: number[], top: number): Promise<any> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("top", [top]);

        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/items/${itemUid}/History/Customer/${customerUid}${p}`);

        return response.data;
    }

    public async getSupplierPurchaseHistoryFromUid(itemUid: string, supplierUid: string, entityIds: number[], top: number): Promise<any> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("top", [top]);

        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/items/${itemUid}/History/Supplier/${supplierUid}${p}`);

        return response.data;
    }

    public async getItemFromId(itemId: number): Promise<Item> {
        const response = await (await this.getApiV2()).get(`/items/${itemId}`);

        const items = this.getItem(response.data);

        return items;
    }

    public async getItemFromUid(itemUid: string): Promise<Item> {
        const response = await (await this.getApiV2()).get(`/items/${itemUid}`);

        const item = this.getItem(response.data);

        return item;
    }

    public async getDocumentLineItemFromUid(itemUid: string): Promise<ItemDocumentLineItem> {
        const response = await (await this.getApiV2()).get(`/items/${itemUid}/document`);

        const item = this.getDocumentLineItem(response.data);

        return item;
    }

    private getDocumentLineItem(data: any): ItemDocumentLineItem {
        const item = new ItemDocumentLineItem(data);

        return item;
    }

    public async saveItem(item: Item): Promise<Item> {
        const response = await (await this.getApiV2()).post(`/items/`, item);

        const items = this.getItem(response.data);

        return items;
    }

    private async getItem(data: any): Promise<Item> {
        const item = new Item(data);

        item.propositions_from = data.propositionsFrom?.sort((a: any, b: any) => a.order_number - b.order_number);
        item.itemRanks = data.itemRanks?.sort((a: any, b: any) => a.order_number - b.order_number);

        const priceLists: PriceList[] = await new PriceListProvider().fetchPriceLists();
        const priceListIds: number[] = item.pricelists!.map((priceList: PriceList) => priceList.price_list_id!)
        
        for (let i = 0; i < priceLists.length; i++) {
            const priceList: PriceList = priceLists[i]
            if (!priceListIds.includes(priceList.id!)) {
                item.pricelists?.push(new ItemPrice({
                    item_id: item.id,
                    item_uid: item.uid,
                    price_list_id: priceList.id,
                    price_list_uid: priceList.uid,
                    discount_rate: priceList.default_discount_rate,
                    price_mode: 1,
                    default_discount_rate: priceList.default_discount_rate,
                    calculated_discount_rate: priceList.calculated_discount_rate,
                }))
            }
        }

        return item;
    }

    public async fetchItemStores(itemUid: string): Promise<ItemStore[]> {
        const response = await (await this.getApiV2()).get(`/items/${itemUid}/stores`);

        const itemStores = response.data.map((d: any) => new ItemStore(d));

        return itemStores;
    }

    public async fetchItemStocks(itemUid: string): Promise<Stock[]> {
        const response = await (await this.getApiV2()).get(`/items/${itemUid}/stocks`);

        const stocks = response.data.map((d: any) => new Stock(d));

        return stocks;
    }

    public async fetchStats(itemIds: number[], storeIds: number[], flowTypeIds: number[]): Promise<IItemStat[]> {
        let p: String = '';
        p = p.addParams("itemIds", itemIds, true);
        p = p.addParams("storeIds", storeIds, true);
        p = p.addParams("flowTypeIds", flowTypeIds, true);
        p = p !== '' ? `?${p}` : '';

        return (await (await this.getApiV2()).get(`/items/Stats${p}`)).data;
    }

    public async fetchStocks(itemIds: number[], storeIds: number[]): Promise<IItemStock[]> {
        let p: String = '';
        p = p.addParams("itemIds", itemIds, true);
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return (await (await this.getApiV2()).get(`/items/Stock${p}`)).data;
    }

    /**
     * Search for suppliers according to its name
     * @param entityIds The entities list suppliers must be linked to
     * @param search The search input
     * @param supplierIds The suppliers to exclude to avoid to search a supplier already listed
     * @returns A collection of suppliers 
     */
    public async searchSuppliers(entityIds: number[], supplierIds: number[], search: string): Promise<Array<any>> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("supplierIds", supplierIds, true);
        p = p.addParams("search", [search]);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/Items/SearchSuppliers${p}`);

        return response.data;
    }

    /**
     * Fetch a summary of the item history 
     */
    public async getItemHistorySummaryFromUid(itemUid: string, entityIds: number[],
        params?: {
            storeIds?: number[];
            posIds?: number[];
            journalIds?: number[],
            journalTypeIds?: number[],
            flowTypeIds?: number[],
            backoffice?: boolean,
            fromDate?: Date,
            toDate?: Date
        }) {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("storeIds", params?.storeIds, true);
        p = p.addParams("posIds", params?.posIds, true);
        p = p.addParams("journalIds", params?.journalIds, true);
        p = p.addParams("journalTypeIds", params?.journalTypeIds, true);
        p = p.addParams("flowTypeIds", params?.flowTypeIds, true);
        p = p.addParams("backoffice", [params?.backoffice], false);

        if (params?.fromDate) p = p.addParams ('fromDate', [moment(params.fromDate).format("yyyy-MM-DD HH:mm:ss")], false);
        if (params?.toDate) p = p.addParams ('toDate', [moment(params.toDate).format("yyyy-MM-DD HH:mm:ss")], false);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/items/${itemUid}/History/Summary${p}`);

        return response.data;
    }
}
