import { InjectionKey } from 'vue'
import { createStore, Store as VuexStore } from 'vuex'
import Matrix from '@/models/matrix'
import ItemListViewModel from '@/models/view/itemlistviewmodel'
import PagedItems from '@/models/paged/pageditems'
import useProvider from '@/hooks/provider'
import MatrixButton from '@/models/matrixbutton'
import { Nullable } from 'primevue/ts-helpers'
import { v4 as uuidv4 } from "uuid";
import Store from '@/models/store'
import MatrixViewModel from '@/models/view/matrixviewmodel'
import MatrixButtonViewModel from '@/models/view/matrixbuttonviewmodel'

export enum LoadingType {
    Matrices,
    Items,
    Saving,
}

export enum ColorMode {
    Light = 'light',
    Dark = 'dark',
}

export interface State {
    matrices: MatrixViewModel[],
    selectedMatrix: MatrixViewModel | null,
    selectedItem: ItemListViewModel | null,
    edited: Boolean,
    reordered: Boolean,
    items: PagedItems | null,
    loading: LoadingType[],
    store: Store | null,
    storeUid: string | null,
    colorMode: ColorMode,
}

export const key: InjectionKey<VuexStore<State>> = Symbol()

export const storeMatrixStore: VuexStore<State> = createStore<State>({
    state: {
        matrices: [],
        selectedMatrix: null,
        selectedItem: null,
        edited: false,
        reordered: false,
        items: null,
        loading: [],
        store: null,
        storeUid: null,
        colorMode: ColorMode.Light,
    },
    mutations: {
        setMatrices(state, matrices: MatrixViewModel[]) {
            state.matrices = matrices;
        },
        setSelectedMatrix(state, matrix: MatrixViewModel | null) {
            state.selectedMatrix = matrix;
        },
        setEdited(state, edited: Boolean) {
            state.edited = edited;
        },
        setSelectedItem(state, item: ItemListViewModel) {
            state.selectedItem = item;
        },
        setItems(state, items) {
            state.items = items;
        },
        setReordered(state, reordered) {
            state.reordered = reordered;
        },
        setStore(state, store) {
            state.store = store;
        },
        setColorMode(state, colorMode: ColorMode) {
            state.colorMode = colorMode;
        },
        setStoreUid(state, storeUid) {
            state.storeUid = storeUid;
        },
        addLoading(state, loading: LoadingType) {
            if (!state.loading.includes(loading))
                state.loading.push(loading);
        },
        removeLoading(state, loading: LoadingType) {
            state.loading.splice(state.loading.indexOf(loading), 1);
        },
    },
    getters: {
        containsLoading: (state) => (loading: LoadingType | LoadingType[]): boolean => {
            if (Array.isArray(loading)) return state.loading.some(l => loading.includes(l));

            return state.loading.includes(loading);
        },
        getMatrixButton: (state) => (row: number, column: number): MatrixButtonViewModel | null => {
            return state.selectedMatrix?.buttons?.find(
                (b) => b.row_index == row && b.column_index == column
            ) ?? null;
        },
        getMatrixButtonFromItem: (state) => (item: ItemListViewModel): MatrixButtonViewModel | null => {
            return state.selectedMatrix?.buttons?.find((b) => {
                return b.itemId == item?.id;
            }) ?? null;
        },
    },
    actions: {
        clear(store) {
            store.state.items = null;
            store.state.matrices = [];
            store.state.edited = false;
            store.state.selectedItem = null;
            store.state.selectedMatrix = null;
            store.state.store = null;
            store.state.storeUid = null;
            store.state.colorMode = ColorMode.Light;
        },
        async initialize(store, storeUid: string): Promise<Store> {
            store.dispatch("clear");

            store.commit("setStoreUid", storeUid);

            const s = await store.dispatch("fetchStore");

            if (!s) return Promise.reject(s);

            return Promise.resolve(s);
        },
        async fetchStore(store): Promise<Store> {
            if (store.state.store) return Promise.resolve(store.state.store);

            if (!store.state.storeUid) return Promise.reject("Undefined storeUid");

            const provider = useProvider();

            const s = await provider.store.getStoreFromUid(store.state.storeUid as string);

            store.commit("setStore", s);

            if (s == null) return Promise.reject(s);

            return Promise.resolve(s);
        },
        async loadMatrices(store): Promise<MatrixViewModel[]> {
            store.commit("addLoading", LoadingType.Matrices);

            store.commit("setSelectedMatrix", null);

            if (store.state.store == null) return Promise.reject();

            const provider = useProvider();

            store.commit("setMatrices", await provider.matrix.fetchStoreMatrixViewModels(Number(store.state.store.id)));

            console.log('store.state.matrices', store.state.matrices)

            await store.dispatch("selectFirstMatrix");

            store.commit("removeLoading", LoadingType.Matrices);

            return Promise.resolve(store.state.matrices);
        },
        async loadItems(store, { sortField, filters }): Promise<PagedItems | null> {
            store.commit("addLoading", LoadingType.Items);

            const provider = useProvider();

            store.commit("setSelectedItem", null);

            const entityIds: number[] = [Number(store.state.store?.entity_id)];

            store.commit("setItems", await provider.item.fetchPagedItems(
                entityIds,
                {
                    pageSize: 100,
                    sortOrder: "asc",
                    sortField: sortField,
                    filters: filters,
                }
            ));

            store.dispatch("selectNextItem");

            store.commit("removeLoading", LoadingType.Items);

            return store.state.items;
        },
        selectFirstMatrix(store): MatrixViewModel | null {
            if (store.state.matrices && store.state.matrices.length > 0) {
                store.dispatch("selectMatrix", store.state.matrices[0]);

                return store.state.selectedMatrix;
            }

            return null;
        },
        selectMatrix(store, matrix: MatrixViewModel | null): void {
            store.commit("setSelectedMatrix", matrix);

            store.commit("setItems", null);

            store.commit("setSelectedItem", null);
        },
        deleteMatrixButton(store, { row, column }): MatrixButtonViewModel | null {
            const button = store.getters.getMatrixButton(row, column);

            if (!button) return null;

            store.state.selectedMatrix?.buttons?.splice(store.state.selectedMatrix?.buttons?.indexOf(button), 1);

            console.log("delete", button);

            store.commit("setEdited", true);

            return button;
        },
        swapMatrixButtons(store, { row, column, oldButton, newButton }): void {
            const oldRow = oldButton?.row_index;
            const oldColumn = oldButton?.column_index;

            if (oldButton) {
                oldButton.row_index = row;
                oldButton.column_index = column;

                store.commit("setEdited", true);
            }

            if (newButton) {
                newButton.row_index = oldRow;
                newButton.column_index = oldColumn;

                store.commit("setEdited", true);
            }
        },
        emptyButtonClicked(store, { row, column }): MatrixButton | null {
            if (!store.state.selectedItem) return null;

            if (store.getters.getMatrixButtonFromItem(store.state.selectedItem)) return null;

            const newButton = store.dispatch("addNewMatrixButtonFromSelectedItem", { row: row, column: column });

            return null;
        },
        addNewMatrixButtonFromSelectedItem(store, { row, column }): MatrixButtonViewModel | null {
            if (!store.state.selectedItem) return null;

            const newButton = new MatrixButtonViewModel({
                button: new MatrixButton({
                    uid: uuidv4(),
                    name_fr: store.state.selectedItem.name_fr,
                    name_en: store.state.selectedItem.name_en,
                    name_nl: store.state.selectedItem.name_nl,
                    action_parameter_content: JSON.stringify({
                        item_id: store.state.selectedItem.id,
                        quantity: 1.0,
                        change_description: false,
                    }),
                }),
                row_index: row,
                column_index: column,
            });

            store.state.selectedMatrix?.buttons?.push(newButton);

            store.commit("setEdited", true);

            store.dispatch("selectNextItem");

            return newButton;
        },
        selectNextItem(store): ItemListViewModel | null {
            if (!store.state.items?.data) return null;

            const index = store.state.selectedItem
                ? store.state.items.data.indexOf(store.state.selectedItem)
                : -1;

            store.commit("setSelectedItem", null);

            let nextIndex: Nullable<number> = null;

            if (index == -1) {
                nextIndex = 0;
            } else if (index >= 0 && index < store.state.items.data.length - 1) {
                nextIndex = index + 1;
            }

            if (nextIndex != null) {
                const nextItem = store.state.items.data[nextIndex];

                if (store.getters.getMatrixButtonFromItem(nextItem)) {
                    store.commit("setSelectedItem", nextItem);
                    store.dispatch("selectNextItem");

                    return null;
                }

                const item = store.state.items.data[nextIndex];

                store.commit("setSelectedItem", item);

                return item;
            }

            return null;
        },
        fillFromMethod(store, method: string): void {
            console.log("fillMethodSelected", method);
            const selectedMatrix = store.state.selectedMatrix;
            const selectedItem = store.state.selectedItem;

            if (!selectedMatrix) return;
            if (!selectedItem) return;

            if (method == "horizontal") {
                for (let row = 1; row <= selectedMatrix.nb_rows; row++) {
                    if (!selectedItem) break;

                    for (
                        let column = 1;
                        column <= selectedMatrix.nb_columns;
                        column++
                    ) {
                        if (!selectedItem) break;

                        const button = store.getters.getMatrixButton(row, column);

                        if (!button) {
                            store.dispatch("addNewMatrixButtonFromSelectedItem", { row, column });
                        }
                    }
                }
            } else if (method == "vertical") {
                for (let column = 1; column <= selectedMatrix.nb_columns; column++) {
                    if (!selectedItem) break;

                    for (
                        let row = 1;
                        row <= selectedMatrix.nb_rows;
                        row++
                    ) {
                        if (!selectedItem) break;

                        const button = store.getters.getMatrixButton(row, column);

                        if (!button) {
                            store.dispatch("addNewMatrixButtonFromSelectedItem", { row, column });
                        }
                    }
                }
            }
        },
        emptySelectedMatrix(store): void {
            if (store.state.selectedMatrix?.buttons)
                store.state.selectedMatrix.buttons = [];

            store.commit("setEdited", true);
        },
        async saveSelectedMatrix(store): Promise<MatrixViewModel | null> {
            store.commit("addLoading", LoadingType.Saving);

            const matrix = store.state.selectedMatrix;

            if (!matrix) return null;

            console.log("save matrix", matrix);

            const provider = useProvider();

            return new Promise((resolve, reject) => {

                store.state.selectedMatrix?.buttons?.forEach(b => {
                    if (!b.itemId) {
                        store.state.selectedMatrix?.buttons?.splice(store.state.selectedMatrix?.buttons?.indexOf(b), 1);
                    }
                });

                provider.matrix.saveStoreMatrixViewModel(matrix, Number(store.state.store?.id)).then(async response => {
                    const newMatrix = response;

                    await store.dispatch("loadMatrices");

                    store.commit("setEdited", false);

                    store.dispatch("selectMatrix", store.state.matrices.find(m => m.uid == newMatrix.uid));

                    resolve(store.state.selectedMatrix);
                }).catch(error => {
                    console.log("error from saveMatrixViewModel call", error)
                    reject(error);

                }).finally(() => {
                    store.commit("removeLoading", LoadingType.Saving);
                });
            });
        },
        async saveMatricesOrder(store) {
            store.commit("addLoading", LoadingType.Saving);

            console.log("save matrices order numbers");

            const provider = useProvider();

            if (!store.state.store) return Promise.reject("Undefined store.");

            await provider.matrix.reorderStoreMatrixViewModels(store.state.matrices, Number(store.state.store?.id));

            await store.dispatch("loadMatrices");

            store.commit("setReordered", false);

            store.commit("removeLoading", LoadingType.Saving);
        },
        async cancelReorder(store) {
            await store.dispatch("loadMatrices");

            store.commit("setReordered", false);
        },
        reorderMatrices(store) {
            let orderNb = 1;
            store.state.matrices?.forEach(
                (element: any) => (element.order_number = orderNb++)
            );

            store.commit("setReordered", true);
        },
        editMatrixButton(store, button: MatrixButtonViewModel) {
            console.log("edit saved", button);

            const oldButton = store.state.selectedMatrix?.buttons?.find(b => b.row_index == button.row_index && b.column_index == button.column_index);

            if (oldButton && oldButton.button) {
                oldButton.button.name_fr = button.button?.name_fr;
                oldButton.button.name_en = button.button?.name_en;
                oldButton.button.name_nl = button.button?.name_nl;
                oldButton.button.action_parameter_content = button.button?.action_parameter_content;
                oldButton.button.display_stock = button.button?.display_stock;
                oldButton.button.font_color = button.button?.font_color;

                store.commit("setEdited", true);
            }
        },
        clearItems(store) {
            store.commit("setItems", null);
        },
        async addExistingMatrix(store, matrix: Matrix) {
            console.log("add existing matrix", matrix);

            const provider = useProvider();

            await provider.matrix.linkStore(matrix.uid as string, store.state.store?.uid as string, 0);

            await store.dispatch("loadMatrices");

            store.commit("setEdited", false);
        },
        async deleteSelectedMatrix(store) {
            if (!store.state.selectedMatrix) return;

            console.log("deleteSelectedMatrix", store.state.selectedMatrix);

            const provider = useProvider();

            await provider.matrix.unlinkStore(store.state.selectedMatrix.uid as string, store.state.store?.uid as string);

            await store.dispatch("loadMatrices");

            store.commit("setEdited", false);
        },
        async fetchButtonFontColor(store, { row, column }): Promise<any> {
            const button = store.getters.getMatrixButton(row, column);

            if (!button) Promise.resolve(-1);

            return Promise.resolve(button.id?.toString().split("").reverse()[0]);
        },
        switchColorMode(store) {
            if (store.state.colorMode == ColorMode.Light)
                store.commit("setColorMode", ColorMode.Dark);
            else
                store.commit("setColorMode", ColorMode.Light);
        },
    }
})

export function useStoreMatrixStore() {
    return storeMatrixStore;
}