import {InjectionKey, watch} from 'vue';
import { createStore, useStore as baseUseStore, Store as VuexStore, } from 'vuex'

import Store from '@/models/store';
import Entity from '@/models/entity';
import entities from '@/views/Setting/Entities.vue';
import useProvider from '@/hooks/provider';
import {deepCopy} from '@/helpers/deepCopy';
import {authStore} from '@/stores/authstore';
import Organization from '@/models/organization';
import {Period} from '@/models/common/period';

export const lineOptions = [10,15,20,25,50,100,200];

export interface State {
    ready: boolean,
    available: {
        entityIds: number[],
        storeIds: number[]
    },
    selected: {
        entityIds: number[],
        storeIds: number[]
    },
    organisation: Organization | null,
    entities: Entity[],
    stores: Store[],
    period?: Period,
    lines: number
}

const initialState: State = {
    ready: false,
    available: {
        entityIds: [],
        storeIds: []
    },
    selected: {
        entityIds: [],
        storeIds: []
    },
    organisation: null,
    entities: [],
    stores: [],
    lines: 50
}

export const globalStore: VuexStore<State> = createStore<State>({
    state: initialState,
    mutations: {
        ready (state, payload: boolean) { state.ready = payload },
        load (state, payload: Partial<State>) {
            state.ready = true;
            if (payload.selected) state.selected = deepCopy(payload.selected);
            if (payload.available) state.available = deepCopy(payload.available);
            if (payload.organisation) state.organisation = deepCopy(payload.organisation);
            if (payload.entities) state.entities = deepCopy(payload.entities);
            if (payload.stores) state.stores = deepCopy(payload.stores);
        },
        setEntityIds(state, entityIds: number[]) {
            state.available.entityIds =
                state.entities
                    .map(e => e.id!);
            const t = entityIds.filter(x => state.available.entityIds.includes(x));
            const newEntityIds = t.filter(x => !state.selected.entityIds.includes(x));
            state.selected.entityIds = t;
            state.available.storeIds =
                state.stores
                    .filter(e => state.selected.entityIds.includes(e.entity_id!))
                    .map(e => e.id!);
            state.selected.storeIds = state.stores
                .filter(e => state.selected.storeIds.includes(e.id!) &&
                                    state.selected.entityIds.includes(e.entity_id!) ||
                                    newEntityIds.includes(e.entity_id!))
                .map(e => e.id!);
        },
        setStoreIds(state, storeIds: number[]) {
            state.selected.storeIds = state.stores
                .filter(e => state.available.storeIds.includes(e.id!) &&
                                    storeIds.includes(e.id!))
                .map(e => e.id!);
        },
        setPeriod(state, period: Period){
            state.period = period
        },
        setLines(state, lines: number) {
            state.lines = lines
        }
    },
    getters: {
        ready(state){ return state.ready },
        currentOrganisation(state) {
            return state.organisation
        },
        availableEntities(state){ return state.entities.filter(e => state.available.entityIds.includes(e.id!)); },
        availableStores(state){ return state.stores.filter(e => state.available.storeIds.includes(e.id!)); },
        selectedEntities(state){ return state.entities.filter(e => state.selected.entityIds.includes(e.id!)); },
        selectedStores(state){ return state.stores.filter(e => state.selected.storeIds.includes(e.id!)); },
        period(state) { return state.period },
        lines(state) { return state.lines }
    },
    actions: {
        async load(store) {
            store.commit('ready', false);
            const provider = useProvider();
            
            const organisation = await provider.organization.fetchOrganization()

            const entities = await provider.entity.fetchEntities() ?? [];
            const entityIds = entities.map(e => e.id!);

            const stores = await provider.store.fetchStores();

            store.commit('load', {
                available: {
                    entityIds: entities.map(e => e.id!),
                    storeIds: stores.filter(e => entityIds.includes(e.entity_id!)).map(e => e.id!)
                },
                selected: {
                    entityIds: entities.map(e => e.id!),
                    storeIds: stores.filter(e => entityIds.includes(e.entity_id!)).map(e => e.id!)
                },
                organisation,
                entities,
                stores
            })
        },
        async refreshEntities(store) {
            const provider = useProvider();

            const entities = await provider.entity.fetchEntities() ?? [];
            const entityIds = entities.map(e => e.id!);

            const stores = await provider.store.fetchStores();
            store.commit('load', {
                available: {
                    entityIds: entities.map(e => e.id!),
                    storeIds: stores.filter(e => entityIds.includes(e.entity_id!) && store.state.available.storeIds.includes(e.id!)).map(e => e.id!)
                },
                selected: {
                    entityIds: entities.filter(e => store.state.selected.entityIds.includes(e.id!)).map(e => e.id!),
                    storeIds: stores.filter(e => entityIds.includes(e.entity_id!) && store.state.selected.storeIds.includes(e.id!)).map(e => e.id!)
                },
                entities,
                stores
            })
        },
        async refreshStores(store) {
            const provider = useProvider();

            const stores = await provider.store.fetchStores();

            store.commit('load',  {
                    available: {
                        entityIds: store.state.entities.map(e => e.id!),
                        storeIds: stores.filter(e => store.state.selected.entityIds.includes(e.entity_id!) && store.state.available.storeIds.includes(e.id!)).map(e => e.id!)
                    },
                    selected: {
                        entityIds: store.state.entities.filter(e => store.state.selected.entityIds.includes(e.id!)).map(e => e.id!),
                        storeIds: stores.filter(e => store.state.selected.entityIds.includes(e.entity_id!) && store.state.selected.storeIds.includes(e.id!)).map(e => e.id!)
                    },
                entities: store.state.entities,
                stores
            });
        },
        clear(store) { store.commit('load', initialState); },
        setEntityIds(store, entityIds: number[]) {
            store.commit('setEntityIds', entityIds);
        },
        setStoreIds(store, storeIds: number[]) {
            store.commit('setStoreIds', storeIds);
        },
        setPeriod(store, period: Period) {
            store.commit('setPeriod', period)
        },
        setLines(store, lines: number) {
            store.commit('setLines', lines)
        }
    },
    plugins: [
        (store) => watch(() => authStore.state.globalUser, () => store.dispatch('load'))
    ]
})
export const key: InjectionKey<VuexStore<State>> = Symbol()
export function useGlobalStore() {
    return baseUseStore(key)
}

