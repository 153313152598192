import PropositionTo from "./propositionto";

export default class Proposition {
    public id?: number;
    public uid?: string;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public quantity_type: number = 1;
    public retrieve_price: boolean = false;

    public entities?: any[] = [];
    public propositions_to?: PropositionTo[] = [];

    constructor(init?: Partial<Proposition>) {
        Object.assign(this, init);
    }
}