export default class Journal {
    public id?: number;
    public uid?: string;
    public name?: string;
    public entity_id?: number;
    public store_id?: number;
    public type?: number;
    public flow_type?: number;
    public invoice_customer_uid?: string;
    public credit_note: boolean = false;

    constructor(init?: Partial<Journal>) {
        Object.assign(this, init);
    }
}