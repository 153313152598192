import {createStore, Store as VuexStore} from 'vuex';
import SettingProvider from '@/providers/settingprovider';
import {storeMatrixStore} from '@/stores/storematrixstore';

export interface SettingModel {
    id: number;
    reference: string;
    value: string;
}
export interface SettingState {
    provider: SettingProvider;
    loading: boolean;
    error: Error | null;
    data: SettingModel[] | null;
}

export const settingStore: VuexStore<SettingState> = createStore<SettingState>({
    state: {
        provider: new SettingProvider(),
        loading: false,
        error: null,
        data: null
    },
    mutations: {
        setLoading(state: SettingState, payload: boolean) { state.loading = payload; },
        setError(state: SettingState, payload: Error) { state.error = payload; },
        setData(state: SettingState, payload: SettingModel[] | null) { state.data = payload; }
    },
    getters: {
        provider: state => state.provider,
        reference: (state) => (reference: string) => (state.data?.find(d => d.reference === reference) ?? null)
    },
    actions: {
        async load(store) {
            store.commit('setLoading', true);
            store.commit('setData', null);
            store.commit('setError', null);

            try {
                store.commit('setData', await store.getters.provider.fetchSettings());
            } catch (e) {
                store.commit('setError', e);
            }

            store.commit('setLoading', false);
        }
    }
})

export function useSettingStore() {
    return settingStore;
}
