import POSKiosk from '@/models/poskiosk';

export enum SaleViewRegroupMode {
    NONE,
    RANK,
    SENT_TIME,
    USER_AND_SENT_TIME,
    USER
}

export enum VivawalletMode {
    ECR = 1,
    EMBEDDED = 2,
    API = 3
}

export default class POS {
    public uid?: string;
    public id?: number;
    public device_id?: string;
    public name?: string;
    public license_code?: string;
    public queue_screen_sound?: string;
    public queue_screen_active?: boolean;
    queue_screen_sound_name?: string;
    queue_screen_sound_base64?: string;
    rfid_reader_type?: number;
    rfid_usb_reader_name?: string;
    rfid_display_mode?: string;
    entity_id?: number;
    pos_kiosk?: POSKiosk;
    prefix_number?: string;

    regroup_same_item_on_print_dispatch?: boolean;
    regroup_same_item?: boolean;
    sale_view_regroup_same_item?: boolean;
    sale_view_regroup_mode?: SaleViewRegroupMode;
    show_cancel_line?: boolean;
    create_kitchen_tasks?: boolean;
    remove_rank_on_order_delivery?: boolean;
    vivawallet_mode?: VivawalletMode;
    vivawallet_ip?: string;
    vivawallet_port?: number;
    vivawallet_embedded?: boolean;
    vivawallet_print_embedded?: boolean;
    vivawallet_terminal_id?: number;
    vivawallet_api_terminal_merchant_id?: string;
    vivawallet_api_user_name?: string;
    vivawallet_api_password?: string;
    customer_webapp_allow_refund?: boolean;
    customer_webapp_login_type?: number;
    customer_webapp_allow_credit?: boolean;

    constructor(init?: Partial<POS>) {
        this.rfid_reader_type = 0;
        Object.assign(this, init);
    }
}
