export default class MatrixButton {
    public id?: number;
    public uid?: string;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public font_color?: number;
    public action_parameter_content?: string;
    public display_stock?: boolean = false;
    public item_level1_color?: string;

    constructor(init?: Partial<MatrixButton>) {
        Object.assign(this, init);
    }
}