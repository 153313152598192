import GlobalUser from "@/models/globaluser"
import PagedGlobalUsers from "@/models/paged/pagedglobalusers";
import BaseProvider from "./baseprovider"
import {types} from 'sass';
import List = types.List;
import {PagedParams} from '@/models/paged/pagedparams';
import GlobalUserPermission from '@/models/globaluserpermission';

export interface fetchGlobalUsersParams {
    entityIds?: number[];
    isAdmin?: boolean;
    system?: boolean;
    template?: boolean;
    name?: string;
}
export interface fetchPagedGlobalUsersParams {
    pagedParams: PagedParams;
    entityIds?: number[];
    isAdmin?: boolean;
    system?: boolean;
    template?: boolean;
}
export default class GlobalUserProvider extends BaseProvider {
    /**
     * fetchGlobalUser
     */
    public async fetchGlobalUser(): Promise<GlobalUser> {
        const response = await (await this.getApiV1()).get('/globalusers/me');

        return Promise.resolve(new GlobalUser(response.data));
    }

    public async fetchGlobalUsersFromOrganizationSystem(search: string): Promise<any[]> {
        const response = await (await this.getApiV1()).get(`/globalusers/fromorganizationsystem?search=${search}`);

        return response.data;
    }

    public async resetPassword(email: string): Promise<any> {
        const response = await (await this.getPublicApiV1()).post('/globalusers/resetpassword', { email: email, });

        return Promise.resolve(new GlobalUser(response.data));
    }

    public async changePasswordWithToken(token: string, password: string): Promise<any> {
        const response = await (await this.getPublicApiV1()).post('/globalusers/changepasswordwithtoken', {
            password: password,
            token: token,
        });

        return Promise.resolve(new GlobalUser(response.data));
    }

    public async validatePasswordToken(token: string): Promise<any> {
        const response = await (await this.getPublicApiV1()).post('/GlobalUsers/ValidateToken', { token: token });

        return Promise.resolve(response.data);
    }

    public async fetchGlobalUsers(params: fetchGlobalUsersParams): Promise<GlobalUser[]> {
        let p: String = '';
        p = p.addParams("entityIds", params.entityIds, true);
        p = p.addParams("isAdmin", [params.isAdmin]);
        p = p.addParams("system", [params.system]);
        p = p.addParams("template", [params.template]);
        p = p.addParams('name', [params.name]);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`/globalusers${p}`);

        return Promise.resolve(response.data);
    }


    public async fetchPagedGlobalUsers(params: fetchPagedGlobalUsersParams): Promise<PagedGlobalUsers> {
        let p: String = '';
        p = p.addPagedParams(params.pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc',
        });
        p = p.addParams("entityIds", params.entityIds, true);
        p = p.addParams("isAdmin", [params.isAdmin]);
        p = p.addParams("system", [params.system]);
        p = p.addParams("template", [params.template]);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`/globalusers${p}`);

        const pagedItems = new PagedGlobalUsers(response.data);

        return pagedItems;
    }

    public async getGlobalUserFromUid(globalUserUid: string): Promise<GlobalUser> {
        const response = await (await this.getApiV1()).get(`/globalusers/${globalUserUid}`);

        const globalUsers = this.getGlobalUser(response.data);

        return globalUsers;
    }

    public async getGlobalUserFromId(globalUserId: Number): Promise<GlobalUser> {
        const response = await (await this.getApiV1()).get(`/globalusers/${globalUserId}`);

        const globalUsers = this.getGlobalUser(response.data);

        return globalUsers;
    }

    public async saveGlobalUser(globalUser: GlobalUser): Promise<GlobalUser> {
        const response = await (await this.getApiV1()).post(`/globalusers/`, globalUser);

        const savedGlobalUser = this.getGlobalUser(response.data);

        return savedGlobalUser;
    }

    private getGlobalUser(data: any): GlobalUser {
        const r = new GlobalUser();
        r.fromJson(data);
        return r;
    }
}
