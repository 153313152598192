export default class ItemStore {
    id?: number;
    item_uid?: string;
    out_of_stock?: boolean;
    store_id?: number;

    constructor(init?: Partial<ItemStore>) {
        Object.assign(this, init);
    }
}
