import useProvider from '@/hooks/provider'
import { SaleRepository } from '@/models/repository/salerepository'
import { CommercialState, CommercialStore, ISendParams, ITransferParams } from './commercialstore';
import { ActionTree } from 'vuex';
import { DocumentLoadingType } from './documentstore';


export interface SaleState extends CommercialState<SaleRepository> {

}

export class SaleStore extends CommercialStore<SaleRepository, SaleState> {
    static _saleStore: SaleStore;

    public async saveDraftTransferRepository(repository: SaleRepository, params: ITransferParams): Promise<any> {
        const provider = useProvider();

        return await provider.sale.saveDraftTransfer(repository, params.journalUid, params.lines);
    }

    public async transferRepository(repository: SaleRepository, params: ITransferParams): Promise<any> {
        const provider = useProvider();

        return await provider.sale.transfer(repository, params.journalUid, params.lines);
    }

    public async sendRepository(repository: SaleRepository, params: ISendParams): Promise<any> {
        const provider = useProvider();

        return await provider.sale.send(repository, params.email);
    }

    public async previewRepository(repository: SaleRepository): Promise<any> {
        const provider = useProvider();

        return await provider.sale.preview(repository.header.model.uid!);
    }

    public async saveRepository(repository: SaleRepository): Promise<any> {
        const provider = useProvider();

        return await provider.sale.save(repository);
    }

    private async depositRepository(repository: SaleRepository, amount: number,): Promise<any> {
        const provider = useProvider();

        return await provider.sale.deposit(repository.header.model.uid!, amount);
    }

    public async getJSONRepository(documentHeaderUid: string): Promise<any> {
        const provider = useProvider();

        return await provider.sale.getSaleJSON(documentHeaderUid);
    }

    public async initializeRepository(repository: SaleRepository): Promise<SaleRepository> {
        await repository.fetchJournal().then(journal => {
            repository.header.model.store_id = journal.store_id;
            repository.header.customerUid = journal.invoice_customer_uid;
            repository.header.edited = false;
        });

        return repository;
    }

    public async getRepository(documentHeaderUid: string): Promise<SaleRepository> {
        const provider = useProvider();

        return await provider.sale.getSaleRepository(documentHeaderUid);
    }

    getActions(): ActionTree<SaleState, SaleState> {
        const that = this;

        return {
            ...super.getActions(),
            async deposit(store, { amount }): Promise<SaleRepository | null> {
                store.commit("addLoading", DocumentLoadingType.Saving);

                const repository = store.state.repository;

                if (!repository) return null;

                console.log("deposit sale", repository);

                return new Promise((resolve, reject) => {
                    that.depositRepository(repository, amount).then(async (response: any) => {

                        const s = await that.getJSONRepository(response.to_document_uid);

                        store.state.repository?.clear();

                        store.state.repository?.fromJson(s);

                        resolve(store.state.repository);
                    }).catch((error: any) => {
                        console.log("error from deposit call", error)
                        reject(error);

                    }).finally(() => {
                        store.commit("removeLoading", DocumentLoadingType.Saving);
                    });
                });
            },
        }
    }

    public static useStore() {
        if (!SaleStore._saleStore)
            SaleStore._saleStore = new SaleStore();

        return SaleStore._saleStore.useStore();
    }
}