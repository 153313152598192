import PaymentTerm from "@/models/paymentterm";
import BaseProvider from "./baseprovider"

export default class PaymentTermProvider extends BaseProvider {
    public async fetchPaymentTerms(): Promise<PaymentTerm[]> {
        const response = await (await this.getApiV1()).get('/PaymentTerms');

        const titles = response.data.map((d: any) => this.getPaymentTerm(d));

        return titles;
    }

    public async getPaymentTermFromId(paymentTermId: number): Promise<PaymentTerm> {
        const response = await (await this.getApiV1()).get(`/paymentterms/${paymentTermId}`);

        const itemLevels = this.getPaymentTerm(response.data);

        return itemLevels;
    }

    private getPaymentTerm(data: any): PaymentTerm {
        const paymentTerm = new PaymentTerm(data);

        return paymentTerm;
    }
}