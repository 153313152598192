import Entity from "@/models/entity";
import BaseProvider from "./baseprovider"
import PagedEntities from "@/models/paged/pagedentities";
import {PagedParams} from '@/models/paged/pagedparams';
import '../helpers/addParams';

export default class EntityProvider extends BaseProvider {
    public async fetchEntities(): Promise<Entity[]> {
        const response = await (await this.getApiV1()).get('/Entities');
        const entities = response.data.map((d: any) => this.getEntity(d));
        return entities;
    }

    public async fetchPagedEntities(entityIds: number[], pagedParams?: PagedParams): Promise<PagedEntities> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`entities${p}`);
        return new PagedEntities(response.data);
    }

    public async getEntityFromUid(entityUid: string): Promise<Entity> {
        const response = await (await this.getApiV1()).get(`/entities/${entityUid}`);
        return this.getEntity(response.data);
    }

    public async saveEntity(entity: Entity): Promise<Entity> {
        const response = await (await this.getApiV1()).post(`/entities/`, entity);
        const savedEntity = this.getEntity(response.data);
        return savedEntity;
    }

    private getEntity(data: any): Entity {
        return new Entity(data);
    }

    public async getEntityFromUidV2(entityUid: string): Promise<Entity> {
        const response = await (await this.getApiV2()).get(`/entities/${entityUid}`);

        return this.getEntity(response.data);
    }

    public async getEntityFromIdV2(entityId: number): Promise<Entity> {
        const response = await (await this.getApiV2()).get(`/entities/${entityId}`);

        return this.getEntity(response.data);
    }
}
