import Journal from "@/models/journal";
import BaseProvider from "./baseprovider"

export default class JournalProvider extends BaseProvider {
    public async fetchJournals(): Promise<Journal[]> {
        const response = await (await this.getApiV1()).get('/Journals');

        const journals = response.data.map((d: any) => this.getJournal(d));

        return journals;
    }

    public async fetchAvailableJournals(storeIds: number[], type: number, flowType: number): Promise<Journal[]> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p.addParams("type", [type], false);
        p = p.addParams("flowType", [flowType], false);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`/Journals/Available${p}`);

        const journals = response.data.map((d: any) => this.getJournal(d));

        return journals;
    }

    public async fetchLinkedJournals(journalUid: string): Promise<Journal[]> {
        const response = await (await this.getApiV1()).get(`/Journals/${journalUid}/Journals`);

        const journals = response.data.map((d: any) => this.getJournal(d));

        return journals;
    }

    private getJournal(data: any): Journal {
        const journal = new Journal(data);

        return journal;
    }

    public async getJournalFromUid(journalUid: string): Promise<Journal> {
        const response = await (await this.getApiV1()).get(`/journals/${journalUid}`);

        return this.getJournal(response.data);
    }
}