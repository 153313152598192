import Customer from "@/models/customer"
import PagedCustomers from "@/models/paged/pagedcustomers";
import BaseProvider from "./baseprovider"
import {CustomerSale} from '@/models/customersale';
import '../helpers/addParams';
import Sale from '@/models/sale';
import {PagedParams} from '@/models/paged/pagedparams';

export interface getCustomerSalesFromUidParams {
    entityIds?: number[],
    storeIds?: number[],
    journalIds?: number[],
    flowTypeIds?: number[],
    posIds?: number[],
    saleHeaderIds?: number[],
    from?: Date,
    to?: Date,
    search?: string[],
    account?: boolean
}

export default class CustomerProvider extends BaseProvider {
    public async fetchPagedCustomers(entityIds: number[], pagedParams?: PagedParams): Promise<PagedCustomers> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'customer_first_name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`/customers${p}`);

        const pagedCustomers = new PagedCustomers(response.data);

        return pagedCustomers;
    }

    public async getCustomersFromEmail(email: string, {
        exceptCustomerUid = '',
    }) : Promise<Array<Customer>> {
        const response = await (await this.getApiV1()).get(`/customers/email/${email}?exceptCustomerUid=${exceptCustomerUid}`);

        return response.data;
    }

    public async getCustomersFromVAT(vat: string, {
        exceptCustomerUid = '',
    }) : Promise<Array<Customer>> {
        if ((vat?.length ?? 0) == 0) return [];
        const response =
            await (await this.getApiV1())
            .get(`/customers/vat/${vat}?exceptCustomerUid=${exceptCustomerUid}`);
        return response.data;
    }

    public async getCustomerFromUid(customerUid: string): Promise<Customer> {
        const response = await (await this.getApiV1()).get(`/customers/${customerUid}`);

        const customers = this.getCustomer(response.data);

        return customers;
    }

    public async getCustomerFromUidV2(customerUid: string): Promise<Customer> {
        const response = await (await this.getApiV2()).get(`/customers/${customerUid}`);

        const customers = this.getCustomer(response.data);

        return customers;
    }

    public async saveCustomer(customer: Customer): Promise<Customer> {
        const response = await (await this.getApiV1()).post(`/customers/`, customer);

        const customers = this.getCustomer(response.data);

        return customers;
    }

    private getCustomer(data: any): Customer {
        const customer = new Customer(data);

        customer.language = customer.language?.toUpperCase();

        return customer;
    }

    public async getCustomerHistoryFromUid(customerUid: string, params?: getCustomerSalesFromUidParams): Promise<CustomerSale[]> {
        let p: String = '';
        p = p.addParams("entityIds", params?.entityIds, true);
        p = p.addParams("storeIds", params?.storeIds, true);
        p = p.addParams("journalIds", params?.journalIds, true);
        p = p.addParams("flowTypeIds", params?.flowTypeIds, true);
        p = p.addParams("posIds", params?.posIds, true);
        p = p.addParams("saleHeaderIds", params?.saleHeaderIds, true);
        p = p.addParams("from", [params?.from]);
        p = p.addParams("to", [params?.to]);
        p = p.addParams("search", params?.search);
        p = p.addParams("account", [params?.account]);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`/customers/${customerUid}/History${p}`);

        return response.data.map((d: any) => new CustomerSale(d));
    }

    public async getCustomerSalesFromUid(customerUid: string, params?: getCustomerSalesFromUidParams): Promise<Sale[]> {
        let p: String = '';
        p = p.addParams("entityIds", params?.entityIds, true);
        p = p.addParams("storeIds", params?.storeIds, true);
        p = p.addParams("journalIds", params?.journalIds, true);
        p = p.addParams("flowTypeIds", params?.flowTypeIds, true);
        p = p.addParams("posIds", params?.posIds, true);
        p = p.addParams("saleHeaderIds", params?.saleHeaderIds, true);
        p = p.addParams("from", [params?.from]);
        p = p.addParams("to", [params?.to]);
        p = p.addParams("search", params?.search);
        p = p.addParams("account", [params?.account]);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`/customers/${customerUid}/Sales${p}`);

        return response.data.map((d: any) => new Sale(d));
    }
}
