import Api from "@/plugins/api"
import { authStore } from "@/stores/authstore";
import axios from "axios";
import BaseProvider from "./baseprovider";

export default class AuthProvider extends BaseProvider {
    /**
     * login
     */
    public async login(login: string, password: string,): Promise<any> {
        return Api.getOwnerCredentials(login, password);
    }

    /**
     * loginAsGlobalUser
     * @param globalUserUid string
     * @returns 
     */
    public async loginAsGlobalUser(globalUserUid: string): Promise<any> {
        const accessToken = await authStore.dispatch('getAccessTokenAsync');

        return Api.getLoginAsOwnerCredentials(accessToken, globalUserUid,);
    }
}