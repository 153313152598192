import ItemSupplier from "./itemsupplier";
import Stock from "./stock";
import ItemMedia from "./itemmedia";

export interface ItemReference {
    id?: number;
    item_id: number;
    item_reference_type_id: number;
    reference: string;
    item_uid: string;

}

export interface ItemItem {
    id?: number;
    uid: string;
    from_item_uid: string;
    to_item_uid: string;
    quantity: number;
    quantity_type: number;
    retrieve_price: boolean;
    order_number: number;
    sale: boolean;
    purchase: boolean;
    store_transfer: boolean;
    inventory: boolean;
}

export class ItemPrice {
    item_id?: number;
    item_uid?: string;
    price_list_id?: number;
    price_vat_excluded?: number;
    price_vat_included?: number;
    discount_rate?: number;
    price_list_uid?: string;
    price_mode?: number;
    default_discount_rate?: number;
    calculated_discount_rate?: number;

    constructor(init?: Partial<ItemPrice>) {
        Object.assign(this, init);
    }
}

export default class Item {
    [key: string]: any;
    public uid?: string;
    public id?: number;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public price_vat_included?: number;
    public price_vat_excluded?: number;
    public vat_rate?: number;
    public vat_rate_takeaway?: number;
    public active?: boolean = true;

    public propositions_to?: any[] = [];
    public propositions_from?: any[] = [];
    public itemRanks?: any[] = [];
    public itemPrinters?: any[] = [];
    public itemSuppliers?: ItemSupplier[] = [];
    public tags?: any[] = [];
    public allergens?: any[] = [];
    public entities?: any[] = [];
    public stores?: any[] = [];
    public stocks?: Stock[] = [];
    public pricelists?: ItemPrice[] = [];
    public references?: ItemReference[] = [];
    public items?: ItemItem[] = [];
    public itemMedias?: ItemMedia[] = [];

    public fidelity_percent_point?: number;
    public fidelity_point?: number;

    public generate_one_task?: boolean;
    public generate_one_ticket?: boolean;

    public duty_id?: number;
    public duty_capacity?: number;
    public duty_alcohol_degree?: number;
    public intrastat_code_id?: number;
    public is_special?: boolean = false;
    public price_list_manual?: boolean = false;
    
    // Zephyr properties
    public webshop_id?: number;
    public webshop_active?: boolean = false;
    public name2_fr?: string;
    public name2_en?: string;
    public name2_nl?: string;
    public description_fr?: string;
    public description_en?: string;
    public description_nl?: string;
    public webshop_item_level_id?: number;
    public webshop_secondary_name_fr?: string;
    public webshop_secondary_name_nl?: string;
    public webshop_secondary_name_en?: string;

    constructor(init?: Partial<Item>) {
        Object.assign(this, init);
    }
}
