import BaseProvider from '@/providers/baseprovider';
import {PagedParams} from '@/models/paged/pagedparams';
import {SettingModel} from '@/stores/settingstore';
import Rank from '@/models/rank';

export default class SettingProvider extends BaseProvider {
    public async fetchSettings(): Promise<SettingModel[] | null> {
        const response = await (await this.getApiV1()).get('/Settings');

        return response?.data ? response.data.map((d: any) => d as SettingModel)
                              : null;
    }
}
