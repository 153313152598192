import GlobalCategory from "@/models/globalcategory";
import Listing from "@/models/listing";
import ListingViewModel from "@/models/view/listingviewmodel";
import BaseProvider from "./baseprovider"
import {CASummaryModel} from '@/models/dashboard/casummarymodel';
import Api from '@/plugins/api';

export default class DashboardProvider extends BaseProvider {
    public async getCASummary(storeIds: number[], fromDate: string, toDate: string): Promise<CASummaryModel> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';


        return this.received(
            await (await this.getApiV1()).get(`/dashboard/CASummary/${fromDate}/${toDate}${p}`)
        );
    }

    public async getCAReportZ(storeIds: number[], fromDate: string, toDate: string): Promise<object> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV1()).get(`/dashboard/CAReportZ/${fromDate}/${toDate}${p}`)
        )
    }

    public async getCAItemLevels(storeIds: number[], fromDate: string, toDate: string): Promise<object> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV1()).get(`/dashboard/CAItemLevels/${fromDate}/${toDate}${p}`)
        );
    }

    public async getCAGlobalUsers(storeIds: number[], fromDate: string, toDate: string): Promise<object> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV1()).get(`/dashboard/CAGlobalUsers/${fromDate}/${toDate}${p}`)
        )
    }

    public async getPointGlobalUsers(storeIds: number[], fromDate: string, toDate: string): Promise<object> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV1()).get(`/dashboard/PointGlobalUsers/${fromDate}/${toDate}${p}`)
        )
    }

    public async getSalesDetails(storeIds: number[], fromDate: string, toDate: string): Promise<object> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV1()).get(`/dashboard/SalesDetails/${fromDate}/${toDate}${p}`)
        )
    }

    public async getListingViewModels(): Promise<ListingViewModel[]> {
        return this.received(
            await (await this.getApiV1()).get(`/dashboard/listings`),
            d => this.getListingViewModel(d)
        );
    }

    public async getListing(listingId: number): Promise<Listing> {
        return this.received(
            await (await this.getApiV1()).get(`/dashboard/listing/${listingId}`),
            d => this.getListingObject(d),
            false
        )
    }

    public async getListingResults(listingId: number, storeIds: number[], fromDate: string, toDate: string): Promise<object> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV1()).get(`/dashboard/listing/results/${listingId}/${fromDate}/${toDate}${p}`)
        )
    }

    private getListingViewModel(data: any): ListingViewModel {
        return new ListingViewModel({
            listing: new Listing({
                id: data.id,
                name_fr: data.name_fr,
                name_en: data.name_en,
                name_nl: data.name_nl,
                icon_azure_path: data.icon_azure_path,
            }),
            globalCategory: new GlobalCategory({
                id: data.global_category_id,
                name_fr: data.global_category_name_fr,
                name_en: data.global_category_name_en,
                name_nl: data.global_category_name_nl,
            })
        });
    }

    private getListingObject(data: any): Listing {
        const listing = new Listing(data);

        return listing;
    }
}