import useProvider from '@/hooks/provider'
import GlobalUser from '@/models/globaluser'
import Api from '@/plugins/api'
import AuthProvider from '@/providers/authprovider'
import GlobalUserProvider from '@/providers/globaluserprovider'
import localforage from "localforage";
import jwt_decode from 'jwt-decode';

import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store, } from 'vuex'

export interface State {
    globalUser: GlobalUser | null,
    accessToken: string | null,
    refreshToken: string | null,
    systemAccessToken: string | null,
    systemRefreshToken: string | null,
}

export const key: InjectionKey<Store<State>> = Symbol()

export const authStore = createStore<State>({
    state: {
        globalUser: null,
        accessToken: null,
        refreshToken: null,
        systemAccessToken: null,
        systemRefreshToken: null,
    },
    mutations: {
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken;
        },
        setSystemAccessToken(state, systemAccessToken) {
            state.systemAccessToken = systemAccessToken;
        },
        setSystemRefreshToken(state, systemRefreshToken) {
            state.systemRefreshToken = systemRefreshToken;
        },
        setGlobalUser(state, globalUser) {
            state.globalUser = globalUser;
        },
    },
    getters: {
        getAccessToken(state) {
            return state.accessToken;
        },
        getClaims(state) {
            return state.accessToken ? jwt_decode(state.accessToken) : null;
        }
    },
    actions: {
        async logout(store): Promise<boolean> {
            store.commit('setAccessToken', null);
            store.commit('setRefreshToken', null);
            store.commit('setSystemAccessToken', null);
            store.commit('setSystemRefreshToken', null);
            store.commit('setGlobalUser', null);

            await localforage.removeItem('access_token');
            await localforage.removeItem('refresh_token');

            await localforage.removeItem('system_access_token');
            await localforage.removeItem('system_refresh_token');

            return Promise.resolve(true);
        },
        async logoutAs(store): Promise<boolean> {
            await localforage.setItem('access_token', store.state.systemAccessToken);
            await localforage.setItem('refresh_token', store.state.systemRefreshToken);

            await localforage.removeItem('system_access_token');
            await localforage.removeItem('system_refresh_token');

            store.commit('setAccessToken', store.state.systemAccessToken);
            store.commit('setRefreshToken', store.state.systemRefreshToken);
            store.commit('setSystemAccessToken', null);
            store.commit('setSystemRefreshToken', null);

            store.commit('setGlobalUser', null);

            await store.dispatch("getGlobalUserAsync");

            return Promise.resolve(true);
        },
        async getGlobalUserAsync(store): Promise<GlobalUser | null> {
            try {
                if (store.state.globalUser) return Promise.resolve(store.state.globalUser);

                const accessToken = await store.dispatch('getAccessTokenAsync');

                console.log ('App version: ', store.getters.getClaims.Version);

                if (!accessToken) return Promise.reject('No access token to retrieve GlobalUser');

                const globalUser = await new GlobalUserProvider().fetchGlobalUser();

                if (globalUser) {
                    store.commit('setGlobalUser', globalUser);

                    return Promise.resolve(store.state.globalUser);
                }

                return Promise.reject('Error while retrieving global user');
            } catch (error) {
                return Promise.reject('Error while retrieving global user (' + error + ')');
            }
        },
        async getAccessTokenAsync(store): Promise<string | null> {
            if (store.state.accessToken) return Promise.resolve(store.state.accessToken);

            const accessToken = await localforage.getItem('access_token');

            if (accessToken) {
                store.commit('setAccessToken', accessToken);

                return Promise.resolve(store.state.accessToken);
            }

            return Promise.reject('Error while retrieving access token');
        },

        async getSystemAccessTokenAsync(store): Promise<string | null> {
            if (store.state.systemAccessToken) return Promise.resolve(store.state.systemAccessToken);

            const systemAccessToken = await localforage.getItem('system_access_token');

            if (systemAccessToken) {
                store.commit('setSystemAccessToken', systemAccessToken);

                return Promise.resolve(store.state.systemAccessToken);
            }

            return Promise.reject('Error while retrieving system access token');
        },
        async login(store, { login, password }) {
            await store.dispatch("logout");
            const response = await new AuthProvider().login(login, password).catch(error => {
                // console.log('error', error)
                return Promise.reject(error);
            });

            console.log('response login', response);

            if (response) {
                await store.dispatch({
                    type: "setTokens",
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token,
                });
                
                await store.dispatch('getGlobalUserAsync');

                return Promise.resolve(store.state.accessToken);
            }

            return Promise.reject();
        },
        async loginAs(store, globalUserUid) {
            const provider = useProvider();

            const response = await provider.auth.loginAsGlobalUser(globalUserUid).catch(error => {
                return Promise.reject(error);
            });

            console.log('response loginAs', response);

            if (response) {
                await store.dispatch({
                    type: "setLoginAsTokens",
                    accessToken: response.data.access_token,
                    refreshToken: response.data.refresh_token,
                });

                store.commit('setGlobalUser', null);

                await store.dispatch('getGlobalUserAsync');

                return Promise.resolve(store.state.accessToken);
            }

            return Promise.reject();
        },
        async setLoginAsTokens(store, { accessToken, refreshToken }) {
            // set system tokens
            store.commit('setSystemAccessToken', store.state.accessToken);
            store.commit('setSystemRefreshToken', store.state.refreshToken);

            await localforage.setItem('system_access_token', store.state.accessToken);
            await localforage.setItem('system_refresh_token', store.state.refreshToken);

            // set log as tokens
            await store.dispatch({
                type: "setTokens",
                accessToken: accessToken,
                refreshToken: refreshToken,
            });
        },
        async setTokens(store, { accessToken, refreshToken }) {
            await localforage.setItem('access_token', accessToken);
            await localforage.setItem('refresh_token', refreshToken);

            store.commit('setAccessToken', accessToken);
            store.commit('setRefreshToken', refreshToken);
        },
        async refreshToken(store) {
            const refreshToken = store.state.refreshToken ?? await localforage.getItem('refresh_token'); // don't know why store.state.refreshToken is empty sometimes
            
            const response = await Api.refreshToken(refreshToken ?? "NONE");

            await store.dispatch({
                type: "setTokens",
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
            });
        },
    }
})

export function useAuthStore() {
    return baseUseStore(key)
}
