
import { defineComponent, onMounted, PropType, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export enum EmptyIndicatorType {
  emptyList = "empty_list",
  noResult = "no_result",
  error = "error",
}
export default defineComponent({
  props: {
    description: String,
    indicator: String,
    width: {
      type: Number,
      default: 220,
    }
  },

  setup(props) {
    const t = useI18n().t;

    onMounted(async () => {

    });

    return {
      t,
    };
  },
});
