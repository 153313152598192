import { ActionTree, } from 'vuex'
import { DocumentLoadingType, DocumentState, DocumentStore } from './documentstore';
import { ICommercialRepository } from '@/models/repository/commercialrepository';
import { v4 as uuidv4 } from "uuid";

export interface CommercialState<D extends ICommercialRepository> extends DocumentState<D> {
}

export interface ISendParams {
    email: string;
}

export interface ICreditNoteParams {
    journalUid: string;
}

export interface ITransferParams {
    journalUid: string;
    lines: ICommercialLineTransfer[];
}

export interface ICommercialLineTransfer {
    lineUid?: string;
    quantity?: number;
}

export abstract class CommercialStore<D extends ICommercialRepository, S extends CommercialState<D>> extends DocumentStore<D, S> {

    public abstract saveDraftTransferRepository(repository: D, params: ITransferParams): Promise<any>;
    public abstract transferRepository(repository: D, params: ITransferParams): Promise<any>;
    public abstract sendRepository(repository: D, params: ISendParams): Promise<any>;

    getActions(): ActionTree<S, S> {
        const that = this;

        return {
            ...super.getActions(),
            async saveDraftTransfer(store, params: ITransferParams): Promise<D | null> {
                store.commit("addLoading", DocumentLoadingType.Saving);

                const repository = store.state.repository;

                if (!repository) return null;

                console.log("transfer commercial", repository);

                return new Promise((resolve, reject) => {
                    that.saveDraftTransferRepository(repository, params).then(async (response: any) => {
                        store.state.repository?.clear();

                        store.state.repository?.fromJson(response);

                        resolve(store.state.repository);
                    }).catch((error: any) => {
                        console.log("error from save draft transfer call", error)
                        reject(error);

                    }).finally(() => {
                        store.commit("removeLoading", DocumentLoadingType.Saving);
                    });
                });
            },
            async transfer(store, params: ITransferParams): Promise<D | null> {
                store.commit("addLoading", DocumentLoadingType.Saving);

                const repository = store.state.repository;

                if (!repository) return null;

                console.log("transfer commercial", repository);

                return new Promise((resolve, reject) => {
                    that.transferRepository(repository, params).then(async (response: any) => {

                        const s = await that.getJSONRepository(response.to_document.header.uid);

                        store.state.repository?.clear();

                        store.state.repository?.fromJson(s);

                        resolve(store.state.repository);
                    }).catch((error: any) => {
                        console.log("error from transfer call", error)
                        reject(error);

                    }).finally(() => {
                        store.commit("removeLoading", DocumentLoadingType.Saving);
                    });
                });
            },
            async getCreditNote(store, payload: ICreditNoteParams): Promise<D | null> {
                const currentJournal = await store.state.repository?.fetchJournal();

                if (currentJournal?.flow_type != 4) return Promise.reject('Invalid flow type');

                const newRepository = await that.getRepository(store.state.repository?.header.model.uid!);

                newRepository.header.model.id = undefined;
                newRepository.header.model.uid = uuidv4();
                newRepository.header.model.journal_uid = payload.journalUid;
                newRepository.header.model.date = new Date();
                newRepository.header.model.number = undefined;
                newRepository.header.model.deadline = undefined;
                newRepository.header.model.accounting = false;
                newRepository.header.model.accounting_creation = undefined;

                newRepository.lines.forEach(line => {
                    if (line.model.item_uid && line.quantity)
                        newRepository.setLineQuantity(line, line.quantity * -1);

                    const children = newRepository.getChildLines(line) ?? [];

                    line.model.id = undefined;
                    line.model.uid = uuidv4();

                    children.forEach(child => {
                        child.model.id = undefined;
                        child.model.uid = uuidv4();

                        newRepository.setParentLine(child, line);
                    });

                    // line.setEdited(false);
                });

                // reset deadline
                newRepository.setPaymentTermId(newRepository.header.paymentTermId);

                // newRepository.header.setEdited(false);

                return Promise.resolve(newRepository);
            },
            async send(store, payload: ISendParams): Promise<D | null> {
                store.commit("addLoading", DocumentLoadingType.Saving);

                const repository = store.state.repository;

                if (!repository) return null;

                console.log("send commercial", repository);

                return new Promise((resolve, reject) => {
                    that.sendRepository(repository, payload).then(async (response: any) => {
                        resolve(store.state.repository);
                    }).catch((error: any) => {
                        console.log("error from send call", error)
                        reject(error);

                    }).finally(() => {
                        store.commit("removeLoading", DocumentLoadingType.Saving);
                    });
                });
            },
        }
    }
}